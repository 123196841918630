import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './modules/auth.module';
import users from './modules/users.module';
import makros from './modules/makros.module';
import tickets from './modules/tickets.module';
import stats from './modules/stats.module';
import help from './modules/help.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
  },
  modules: {
    auth,
    users,
    makros,
    tickets,
    stats,
    help
  },
  getters: {
    loading(state) {
      return state.loading;
    }
  }
})
