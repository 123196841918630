import router from '@/router';

export default {
    state: {
        agents: [],
    },
    mutations: {
        setAgents(state, agents) {
            state.agents = agents;
        },
        addAgent(state, agent) {
            state.agents = [...state.agents, agent];
        },
        editAgent(state, agent) {
            let index = state.agents.indexOf(state.agents.find(el => el.email == agent.email));
            state.agents.splice(index, 1, agent);
        }
    },
    actions: {
        getAgents({ commit }) {
            this._vm.$axios.get('users/agents').then((data) => {
                commit('setAgents', data.data.data);
            }).catch(err => {
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            });
        },
        addAgent({ commit }, agent) {
            this._vm.$axios.post('users/register', agent).then((data) => {
                commit('addAgent', data.data.user);
            }).catch(err => {
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                }); 
            });
        },
        updateAgent({ commit }, agent) {
            this._vm.$axios.put(`users/agents/${agent.id}`, agent.agent).then((data) => {
                commit('editAgent', data.data.user);
            }).catch(err => {
                console.log(err);
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                }); 
            });
        },
        removeAgent({ commit }, agent_id) {
            this._vm.$axios.get(`users/agents/delete/${agent_id}`).then((data) => {
                commit('setAgents', data.data.data);
            }).catch(err => {
                console.log(err);
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            })
        },
        changePw({ commit }, pwdata) {
            this._vm.$axios.post(`users/changepw`, pwdata).then((data) => {
                router.push('/dashboard');
            }).catch(err => {
                console.log(err);
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            })
        }
    },
    getters: {
        agents(state) {
            return state.agents;
        }
    }
}