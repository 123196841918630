import router from '@/router';

export default {
    state: {
        makros: [],
        makro: {}
    },
    mutations: {
        setMakros(state, makros) {
            state.makros = makros;
        },
        addMakro(state, makro) {
            state.makros = [...state.makros, makro];
        },
        setMakro(state, makro) {
            state.makro = makro;
        },
        editMakro(state, makro) {
            let index = state.makros.indexOf(state.makros.find(el => el.title == makro.title));
            state.makros.splice(index, 1, makro);
        }
    },
    actions: {
        getMakros({ commit }) {
            this._vm.$axios.get('makros').then((data) => {
                commit('setMakros', data.data.makros);
            }).catch(err => {
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            });
        },
        getMakro({ commit }, makro_id) {
            this._vm.$axios.get(`makros/${makro_id}`).then((data) => {
                commit('setMakro', data.data.makro);
            }).catch(err => {
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            });
        },
        addMakro({ commit }, makro) {
            this._vm.$axios.post('makros', makro).then((data) => {
                commit('addMakro', data.data.makro);
            }).catch(err => {
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                }); 
            });
        },
        updateMakro({ commit }, makro) {
            this._vm.$axios.put(`makros/${makro.id}`, makro.makro).then((data) => {
                commit('editMakro', data.data.makro);
                router.push('/makros');
            }).catch(err => {
                console.log(err);
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                }); 
            });
        },
        removeMakro({ commit }, makro_id) {
            this._vm.$axios.get(`makros/delete/${makro_id}`).then((data) => {
                commit('setMakros', data.data.data);
            }).catch(err => {
                console.log(err);
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            })
        }
    },
    getters: {
        makros(state) {
            return state.makros;
        },
        makro(state) {
            return state.makro;
        }
    }
}