<template>
  <transition name="fadepage" mode="out-in">
    <div
      class="image-modal max-index flex top-left full-width-height align-center no-overflow p-fixed justify-center"
      v-if="showModal"
    >
      <button @click="close" type="button" class="close-btn absolute">
        <svg viewbox="0 0 40 40">
          <path
            stroke="white"
            fill="transparent"
            stroke-linecap="round"
            stroke-width="5"
            d="M 10,10 L 30,30 M 30,10 L 10,30"
          />
        </svg>
      </button>
      <img v-click-outside="close" class="img-cover" :src="comingSource" />
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  .image-modal {
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    overflow: hidden;
    position: fixed;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    padding: 10rem;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .close-btn {
      right: 30px;
      top: 30px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
</style>

<script>
  export default {
    name: 'ImageModal',
    props: ['show', 'source'],
    data() {
      return {
        showModal: false,
        comingSource: '',
      };
    },
    methods: {
      close() {
        this.showModal = false;
        this.comingSource = '';
        this.$emit('closed');
      },
      closeDeleteModal() {
        this.close();
      }
    },
    watch: {
      show: function(newVal, oldVal) {
        this.showModal = newVal;
      },
      source: function(newVal, oldVal) {
        this.comingSource = newVal;
      },
    },
  };
</script>
