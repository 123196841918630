import router from '@/router';

export default {
    state: {
        tickets: [],
        ticketCount: 0,
        recentlySolved: [],
        page: 0,
        ticket: {},
        comments: [],
        solvedTickets: [],
        allTickets: [],
        searchedTickets: [],
        qaTicketUser: '',
        qaTicketStatus: '',
        ticketOrderInfo: [],
        qaTicketFrom: '',
        qaTicketName: '',
        qaTicketOrder: '',
        canReply: []
    },
    mutations: {
        setTickets(state, tickets) {
            state.tickets = tickets;
        },
        setAllTickets(state, tickets) {
            state.allTickets = tickets;
        },
        setSolvedTickets(state, tickets) {
            state.solvedTickets = tickets;
        },
        setRecently(state, tickets) {
            state.recentlySolved = tickets;
        },
        setCount(state, count) {
            state.ticketCount = count;
        },
        setPage(state, page) {
            state.page = page;
        },
        setTicket(state, ticket) {
            state.ticket = ticket;
        },
        setComments(state, comments) {
            state.comments = comments;
        },
        removeTicket(state, ticketId) {
            state.tickets = state.tickets.filter(el => el.id != ticketId);
        },
        setSearchedTickets(state, tickets) {
            state.searchedTickets = tickets;
        },
        setQaTicketUser(state, user) {
            state.qaTicketUser = user;
        },
        setQaTicketStatus(state, data) {
            state.qaTicketStatus = data;
        },
        setTicketOrderInfo(state, data) {
            state.ticketOrderInfo = data;
        },
        setQaTicketFrom(state, data) {
            state.qaTicketFrom = data;
        },
        setQaTicketOrder(state, data) {
            state.qaTicketOrder = data;
        },
        setQaTicketName(state, data) {
            state.qaTicketName = data;
        },
        canReply(state, data) {
            state.canReply = data;
        }
    },
    actions: {
        getAllTickets({ commit }) {
            this._vm.$axios('tickets/all').then(data => {
                commit('setAllTickets', data.data.tickets);
            }).catch(e => {console.log(e)})
        },
        removeTicket({ commit }, ticketId) {
            commit('removeTicket', ticketId);
        },
        async fetchTicketCount({ commit }, tag) {
            await this._vm.$axios.get(`tickets/count?tag=${tag}`).then(data => {
                let totalTicketCount = data.data.ticketCount
                commit('setCount', totalTicketCount);
            }).catch(error => {
                console.log(error)
            })
        },
        getTickets({ commit }, { tag, amount }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`tickets?tag=${tag}&amount=${amount}`).then(data => {
                let movedTickets = localStorage.getItem('moved-tickets') || '';
                let movedTicketsArray = movedTickets.split(',');
                let tickets = data.data.tickets.filter(el => !movedTicketsArray.includes((el.id).toString()))
                
                commit('setTickets', tickets);
                commit('setLoading', false, { root: true });
            }).catch(err => console.log(err));
        },
        getSolvedTickets({ commit }, tag) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`tickets/solved?tag=${tag}`).then(data => {
                commit('setSolvedTickets', data.data.tickets);
                commit('setLoading', false, { root: true });
            }).catch(err => console.log(err));
        },
        getRecently({ commit }, tag) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`tickets/recently?tag=${tag}`).then(data => {
                commit('setRecently', data.data.tickets);
                commit('setLoading', false, { root: true });
            }).catch(err => console.log(err));

        },
        async getTicket({ commit }, id) {
            commit('setTicket', null);
            try {
                const response = await this._vm.$axios.get(`tickets/${id}`);
                commit('setTicket', response.data.ticket);
            } catch (error) {
                console.error(`Failed to fetch ticket with ID ${id}`, error);
                return undefined;
            }
            // const data = await  this._vm.$axios.get(`tickets/${id}`).then(data => {
            //     console.log('da data in here:', data)
            //     commit('setTicket', data.data.ticket);
            //     return data.data.ticket; 
            // }).catch(err => console.log(err));
        },
        getTicketComments({ commit }, id) {
            commit('setComments', []);
            this._vm.$axios.get(`tickets/${id}/comments`).then(data => {
                commit('setComments', data.data.comments);
            }).catch(err => console.log(err));
        },
        addComment({ commit }, { id, content }) {
            router.push('/dashboard');
            this._vm.$axios.post(`tickets/${id}`, content).then(data => {
                commit('setComments', data.data.comments);
            }).catch(err => {
                console.log(err);
            })
        },
        moveTo({ commit, dispatch}, { id, to }) {
            this._vm.$axios.post(`tickets/${id}/open`, { to }).then(data => {
                dispatch('removeTicket',id)
                router.push('/dashboard');
            })
        },
        sendEmail({ commit }, { id, content }) {
            this._vm.$axios.post(`tickets/${id}/email`, content).then(data => {
                router.push('/dashboard');
            })
        },
        setPage({ commit }, page) {
            commit('setPage', page);
        },
        getSolvedByDate({ commit }, { date, tag }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`tickets/solved/${date}/?tag=${tag}`).then(data => {
                commit('setSolvedTickets', data.data.tickets);
                commit('setLoading', false, { root: true });
            })
        },
        writeTicket({ commit }, data) {
            this._vm.$axios.post('tickets/new', data).then(res => {
                router.push('/dashboard')
            }).catch(e => console.log(e));
        },
        sendQaEmail({ commit }, data) {
            this._vm.$axios.post(`tickets/qa-email`, data).then(res => {
                this._vm.$toast.open({
                    message: "E-Mail erfolgreich gesendet!",
                    type: 'success'
                });
            }).catch(e => {console.log(e)})
        },
        getSearchedTickets({ commit }, search) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`tickets/qa-search?filter=${search}`).then(data => {
                commit('setSearchedTickets', data.data.tickets);
                commit('setLoading', false, { root: true });
            })
        },
        getQaTicketUser({ commit }, id) {
            const loggedUserJson  = window.localStorage.getItem('zendeskLoggedUser')
            const loggedUser = JSON.parse(loggedUserJson)
            commit('setLoading', true, { root: true });
            commit('setQaTicketUser', '');
            commit('setQaTicketStatus', '');
            commit('setQaTicketFrom', '');
            commit('setQaTicketOrder', '');
            commit('setQaTicketName', '')
            commit('canReply', '')
            this._vm.$axios.get(`tickets/qa-search/${id}`, {
                headers: {
                    'Logged-User-Tag': loggedUser.tag,
                }
            }).then(data => {
                commit('setQaTicketUser', data.data.data);
                commit('setQaTicketStatus', data.data.status);
                commit('setQaTicketFrom', data.data.from);
                commit('setQaTicketOrder', data.data.order);
                commit('setQaTicketName', data.data.name);
                commit('canReply', data.data.canReply);
                commit('setLoading', false, { root: true });
            })
        },
        getOrderInformation({ commit }, id) {
            commit('setTicketOrderInfo', []);
            if (id === null || id === undefined) {
                console.error('Error: The id is null or undefined.');
                return;
            }
            this._vm.$axios.get(`features/order-info?id=${id.replace('#', 'VA')}`).then(data => {
                commit('setTicketOrderInfo', data.data);
            }).catch(e => console.log(e));
        }
    },
    getters: {
        tickets(state) {
            return state.tickets;
        },
        getTicketCount(state) {
            return state.ticketCount;
        },
        page(state) {
            return state.page;
        },
        recentlySolved(state) {
            return state.recentlySolved;
        },
        ticket(state) {
            return state.ticket;
        },
        comments(state) {
            return state.comments;
        },
        solvedTickets(state) {
            return state.solvedTickets;
        },
        allTickets(state) {
            return state.allTickets;
        },
        searchedTickets(state) {
            return state.searchedTickets
        },
        qaTicketUser(state) {
            return state.qaTicketUser;
        },
        qaTicketStatus(state) {
            return state.qaTicketStatus;
        },
        qaTicketFrom(state) {
            return state.qaTicketFrom;
        },
        qaTicketOrder(state) {
            return state.qaTicketOrder;
        },
        qaTicketName(state) {
            return state.qaTicketName;
        },
        canReply(state) {
            return state.canReply;
        },
        ticketOrderInfo(state) {
            return state.ticketOrderInfo;
        }
    }
}