import router from '@/router';

export default {
    state: {
        today: [],
        quarter: [],
        hour: [],
        week: [],
        month: [],
        year: [],
        decade: []
    },
    mutations: {
        setToday(state, today) {
            state.today = today;
        },
        setQuarter(state, quarter) {
            state.quarter = quarter;
        },
        setHour(state, hour) {
            state.hour = hour;
        },
        setWeek(state, week) {
            state.week = week;
        },
        setMonth(state, monthly) {
            state.month = monthly;
        },
        setYear(state, year) {
            state.year = year;
        },
        setDecade(state, decade) {
            state.decade = decade;
        }
    },
    actions: {
        getToday({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get('stats/daily')
            .then((data) => {
                commit('setToday', data.data.users);
            }).catch(err => {
                this._vm.$toast.open({
                    message: "Fehler aufgetreten", 
                    type: 'error'
                });
            }).finally(() => {
                commit('setLoading', false, { root: false });
            });
        },
        getQuarter({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get('stats/quarterly')
                .then((data) => {
                    commit('setQuarter', data.data.users);
                })
                .catch(err => {
                    console.error('Error loading quarter data:', err);
                })
                .finally(() => {
                    commit('setLoading', false, { root: true });
                });
        },
        
        getHour({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get('stats/hourly')
            .then((data) => {
                commit('setHour', data.data.users);
            }).catch(err => {
                console.log(err);
            }).finally (() => {
                commit('setLoading', false, { root: true });
            });
        },
        getHourByDate({ commit }, date) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`stats/hourly?date=${date}`)
            .then((data) => {
                commit('setHour', data.data.users);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                commit('setLoading', false, { root: true });
            });
        },
        async getDataByTimeframe({ commit }, { date, timeframe }) { 
            if (!(date instanceof Date)) {
                date = new Date(date);
            }
            date = date.toLocaleDateString();
            commit('setLoading', true, { root: true });
            const endpointMap = {
                quarterHour: 'quarterly',
                day: 'daily',
                hour: 'hourly',
                week: 'weekly',
                month: 'monthly',
                year: 'yearly',
                years: 'years'
            };

            const setMap = {
                quarterHour: 'setQuarter',
                day: 'setToday',
                hour: 'setHour',
                week: 'setWeek',
                month: 'setMonth',
                year: 'setYear',
                years: 'setDecade'
            }

            const endpoint = await endpointMap[timeframe] || 'daily';
            await this._vm.$axios.get(`stats/${endpoint}?date=${date}`).then((data) => {
                commit(`${setMap[timeframe]}`, data.data.users);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                commit('setLoading', false, { root: true });
            });
        },
        getWeek({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`stats/weekly`).then((data) => {
                commit('setWeek', data.data.users);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                commit('setLoading', false, { root: true });
            });
        },
        getMonth({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`stats/monthly`).then((data) => {
                commit('setMonth', data.data.users);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                commit('setLoading', false, { root: true });
            });
        },
        getYear({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get('stats/yearly').then((data) => {
                commit('setYear', data.data.users);
            }).catch (error =>  {
                console.error('Error occurred:', error);
            }).finally (() => {
                commit('setLoading', false, { root: true });
            })
        },        
        getDecade({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get(`stats/years`).then((data) => {
                commit('setDecade', data.data.users);
            }).catch(err => {
                console.error('Error occurred:', error);
            }).finally (() => {
                commit('setLoading', false, { root: true });
            });
        }
    },
    getters: {
        today(state) {
            return state.today;
        },
        quarter(state) {
            return state.quarter;
        },
        hour(state) {
            return state.hour;
        },
        week(state) {
            return state.week;
        },
        month(state) {
            return state.month;
        },
        year(state) {
            return state.year;
        },
        decade(state) {
            return state.decade;
        },
    }
}