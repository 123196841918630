import axios from 'axios';

export const axiosInstance = axios.create({
  // baseURL: process.env.baseURL + `/api/v1`,
  baseURL: `https://ourdesk.io/api/v1`,
  // baseURL: `https://ourdesk-v3-production-d7e4.up.railway.app/api/v1`,
  // baseURL: `http://localhost:3000/api/v1`,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = JSON.parse(window.localStorage.getItem('zendeskToken'));
    if (token) {
      config.headers["auth-token"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

if (window.localStorage.zendeskToken) {
  const token = JSON.parse(window.localStorage.getItem('zendeskToken'));
  if (token) {
    axiosInstance.defaults.headers.common['auth-token'] = token;
  } else {
    axiosInstance.defaults.headers.common['auth-token'] = '';
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$axios = axiosInstance;
  },
};
