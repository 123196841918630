import router from '@/router';

export default {
    state: {
        helps: [],
    },
    mutations: {
        setHelps(state, helps) {
            state.helps = helps;
        },
    },
    actions: {
        addHelp({ commit }, help) {
            this._vm.$axios.post('help', help).then(data => {
                router.push('/dashboard');
                this._vm.$toast.open({
                    message: "Ihre Nachricht wurde gesendet", 
                    type: 'success'
                });
            }).catch(e => {});
        },
        getAllHelp({ commit }) {
            this._vm.$axios.get('help').then(data => {
                commit('setHelps', data.data.helps);
            }).catch(e => {});
        },
        sendReply({ commit, getters }, reply) {
            this._vm.$axios.post('help/reply', reply).then(data => {
                this._vm.$toast.open({
                    message: "Ihre Nachricht wurde gesendet", 
                    type: 'success'
                });
            }).catch(e => {})
        },
        deleteHelp({ commit, getters }, index) {
            this._vm.$axios.delete(`help/${index}`).then(data => {
                commit('setHelps', data.data.helps)
                this._vm.$toast.open({
                    message: "Eintrag erfolgreich entfernt!", 
                    type: 'success'
                });
            })
        }
    },
    getters: {
        helps(state) {
            return state.helps;
        },
        sent(state) {
            return state.sent;
        }
    }
}