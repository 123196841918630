import Vue from 'vue'
import VueRouter from 'vue-router'

import { axiosInstance as axios } from '../plugins/axios';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    component: () => import('../views/Base.vue'),
    children: [
      {
        name: 'Home',
        path: '/',
        component: () => import('../views/Home.vue')
      },
      {
        name: 'Makros',
        path: '/makros',
        component: () => import('../views/Makros.vue'),
      },
      {
        name: 'Help',
        path: '/help',
        component: () => import('../views/Help.vue'),
      },
      {
        name: 'QA',
        path: '/qa/:id',
        component: () => import('../views/QATicket.vue'),
      },
      {
        name: 'Settings',
        path: '/settings',
        component: () => import('../views/Settings.vue'),
      },
      {
        name: 'Statistics',
        path: '/stats',
        component: () => import('../views/Statistics.vue'),
      },
      {
        name: 'EditMakro',
        path: '/makros/edit/:id',
        component: () => import('../views/EditMakro.vue')
      },
      {
        name: 'Makro',
        path: '/makros/:id',
        component: () => import('../views/Makro.vue')
      },
      {
        name: 'SolvedTicket',
        path: '/tickets/solved',
        component: () => import('../views/SolvedTickets.vue')
      },
      {
        name: 'NewTicket',
        path: '/tickets/new',
        component: () => import('../views/NewTicket.vue')
      },
      {
        name: 'SearchTickets',
        path: '/tickets/search',
        component: () => import('../views/AllSearch.vue')
      },
      {
        name: 'Ticket',
        path: '/tickets/:id',
        component: () => import('../views/Ticket.vue')
      },
      
    ]
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import('../views/404.vue'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Check for auth
router.beforeEach(async (to, from, next) => {
  const token = JSON.parse(window.localStorage.getItem("zendeskToken"));
  if(to.name !== 'Login') {
    if(!token) {
      next({ name: 'Login' });
    } else {
      const data = await axios.get(`users/checkToken?token=${token}`);
      if(data.data.pass) {
        // CHECK FOR MAKROS - ADMIN
        if(to.path.includes("makros")) {
          if(data.data.admin) {
            next();
          } else {
            next({ name: 'Home' });
          }
        } else {
          next();
        }
        // CHECK FOR TICKETS - ADMIN
        if(to.path.includes("tickets")) {
          if(data.data.qa && to.path != '/tickets/search') {
            next({ name: 'Home' });
          } 
          else {
            next();
          }
        } else {
          next();
        }
      } else {
        next({ name: 'Login' });
      }
    }
  } else {
    if(token) {
      const data = await axios.get(`users/checkToken?token=${token}`);
      if(data.data.pass) {
        next({ name: 'Home' });        
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router
