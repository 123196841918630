import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Plugins
import axios from './plugins/axios';
Vue.use(axios);

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import Loading from '@/components/Loading.vue';
Vue.component('Loading', Loading);

import VueMarkdown from 'vue-markdown';
Vue.component('VueMarkdown', VueMarkdown)

import ImageModal from '@/components/ImageModal.vue';
Vue.component('ImageModal', ImageModal);

// import DatePicker from 'v-calendar/lib/components/date-picker.umd';
// Vue.component('DatePicker', DatePicker);

// Directives
import clickOutsideDirective from './directives/click-outside.directive';
Vue.directive('click-outside', clickOutsideDirective);

// Mixins
import closeEsc from './mixins/closeEsc.mixin';
Vue.mixin(closeEsc);


// Notifications
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
